.address-form-popover {
  padding: 20px;
  .fs {
    .form_element {
      input,
      select {
        width: 100%;
        margin-top: 8px;
        border: 1px solid $color-dark-grey;
      }
    }
  }
  .ship-type-container,
  .default-ship-container {
    @include swap_direction(margin, 10px 0);
  }
}

.address-book-info {
  a {
    &.edit-address,
    &.delete-address {
      width: 48%;
      float: #{$ldirection};
      input.small-button {
        width: 100%;
      }
    }
  }
}

.default-address,
.defaultbill-address {
  input {
    width: 100%;
    white-space: normal;
    margin-bottom: 10px;
  }
}

.password-reset {
  &__content {
    .label {
      span {
        margin-bottom: 5px;
      }
      input {
        display: block;
        border: 1px solid $color-dark-grey;
        font-size: 13px;
      }
    }
  }
}

footer {
  &#footer {
    @include swap_direction(margin, 0);
    height: auto;
  }
}

.device-mobile {
  a {
    input {
      font-weight: normal;
      letter-spacing: 0;
    }
    &.add-address-link {
      input {
        width: 100%;
        font-size: 18px;
      }
    }
    &.edit-address,
    &.delete-address {
      input {
        font-size: 18px;
      }
    }
  }
  footer {
    &#footer {
      height: auto;
      .footer-bottom {
        .field-mobile-menu {
          ul.menu {
            > li {
              &.item-2,
              &.item-4,
              &.item-6 {
                border-#{$rdirection}: 1px solid $color-cl-soft-black;
              }
            }
          }
        }
      }
    }
    .footer-top {
      .newsletter-signup {
        .field {
          label {
            @include swap_direction(padding, 0 0 8px);
          }
        }
      }
    }
  }
  .popup-offer-v1 {
    .subheading {
      @include swap_direction(margin, 0 0 10px 0);
    }
    .form-messages {
      @include swap_direction(margin, 0 0 0 20px);
    }
    .form-submit-wrap {
      .form-submit {
        margin-top: 7px;
      }
    }
  }
  .accordionItem {
    &.acne-breakout {
      h3 {
        span:nth-child(1) {
          background-position: -2px 4px;
          height: 40px;
        }
      }
    }
    &.lines-wrinkles {
      h3 {
        span:nth-child(1) {
          background-position: 0 -74px;
          height: 40px;
        }
      }
    }
    &.dryness {
      h3 {
        span:nth-child(1) {
          background-position: -2px -155px;
          height: 50px;
        }
      }
    }
    &.enlarged-pores {
      h3 {
        span:nth-child(1) {
          background-position: -2px -233px;
          height: 53px;
        }
      }
    }
    &.redness {
      h3 {
        span:nth-child(1) {
          background-position: -2px -312px;
          height: 47px;
        }
      }
    }
    &.dark-spots {
      h3 {
        span:nth-child(1) {
          background-position: -2px -392px;
          height: 46px;
        }
      }
    }
  }
  .spp-product {
    .product-info {
      .price {
        font-size: 18px;
        font-family: $base-font-family;
        padding-left: 15px;
      }
    }
  }
}

.email_waitlist_overlay_container {
  .email_waitlist_popup {
    min-height: 205px;
  }
}

.product-list {
  .product {
    .sbOptions {
      z-index: 10;
    }
  }
}

#top {
  #header {
    .btn_navigation {
      background-position: 0 -9px;
      height: 52px;
      width: 50px;
      float: #{$ldirection};
      position: static;
    }
    .block-template-search-mobile-v2 {
      float: #{$ldirection};
    }
    .site-logo-responsive-v1 {
      .logo-link {
        height: 56px;
        line-height: 56px;
        margin: 0;
        padding: 0;
      }
    }
    .gnav-drop-down-v1 {
      .dd-icon {
        .link {
          em {
            padding: 0;
            #{$ldirection}: 3px;
            text-align: center;
            top: 11px;
          }
        }
      }
    }
  }
  .block-template-gnav-search-v1,
  .block-template-search-mobile-v1,
  .block-template-site-logo-responsive-v1,
  .block-template-gnav-cart-v1 {
    display: inline-block;
    float: #{$ldirection};
  }
}

.elc-user-state-anonymous {
  .navigation_container {
    .field-mobile-menu {
      .menu {
        .parent-mi-cuenta- {
          li:nth-last-child(1) {
            display: none;
          }
        }
      }
    }
  }
}

.elc-user-state-logged-in {
  .navigation_container {
    .field-mobile-menu {
      .menu {
        .parent-mi-cuenta- {
          li:nth-last-child(2) {
            display: none;
          }
        }
      }
    }
  }
}

body {
  #foreground-node {
    .close-container {
      &.close-x-button {
        position: inherit;
      }
    }
    &.overlay-container {
      width: 100% !important;
      height: auto !important;
    }
  }
}

#main {
  .new-account {
    @if $adpl_forms_styles == true {
      input[type='text'] + label {
        line-height: get-line-height(20px, 42px);
      }
    }
  }
  .return-user {
    @if $adpl_forms_styles == true {
      input[type='text'] + label {
        line-height: get-line-height(20px, 62px);
      }
    }
  }
}

.mantle-media-asset {
  img,
  video {
    height: auto;
  }
}
