#pr-review-display {
  .p-w-r {
    .pr-snippet-reco-to-friend {
      .pr-reco-to-friend-message {
        font-size: 15px;
      }
    }
    .pr-review-display {
      .pr-rd-main-header {
        .pr-rd-review-header-contents {
          @media #{$cr19-large-up} {
            width: 42%;
          }
          &::before {
            content: 'Filtrado por: ' !important;
          }
        }
        @media #{$cr19-large-up} {
          .pr-rd-main-header-search-sort {
            width: 58%;
          }
        }
        .pr-rd-review-header-sorts {
          width: 100%;
          @media #{$cr19-large-up} {
            width: 58%;
            .pr-rd-sort-group {
              float: right;
              padding-left: 1px;
            }
          }
          .pr-rd-sort {
            background: url('/media/images/stars/down-arrow.png') no-repeat scroll 95% 0 transparent;
          }
        }
        @media #{$cr19-large-up} {
          .pr-rd-main-header-search {
            width: 42%;
            float: right;
            padding-left: 0;
            input {
              font-size: 13px;
            }
          }
        }
      }
      .pr-rd-sort-group {
        &::before {
          content: 'Ordenar por: ' !important;
        }
      }
      .pr-multiselect {
        .pr-multiselect-button {
          padding: 0 16px 0 0;
          margin: 5px 2px 0 0;
        }
      }
      .pr-review {
        .pr-rd-footer {
          .pr-helpful-yes {
            .pr-helpful-count {
              &::before {
                content: 'Si' !important;
              }
            }
          }
          .pr-helpful-no {
            .pr-helpful-count {
              &::before {
                content: 'No' !important;
              }
            }
          }
          .pr-modal-content {
            .pr-flag-review-btn {
              width: 100%;
              &.pr-flag-review-btn-cancel {
                margin-top: 3%;
              }
            }
          }
          .pr-rd-flag-review-container {
            clear: both;
            padding: 10px 0;
            a {
              padding-left: 0;
            }
          }
        }
      }
    }
  }
}
