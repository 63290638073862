body.vto-active snappylabs-chat {
  display: none;
}

.elc-vto-foundation {
  .elc-vto-foundation-shade-grid {
    .elc-vto {
      &-tab {
        font-size: 11px;
        white-space: normal;
        &-container {
          margin-bottom: 8px;
        }
      }
      &-perfect-shade-label {
        font-size: 12px;
        width: auto;
      }
      &-custom-shade-picker {
        width: 100%;
        .slick-slide:nth-child(2) {
          flex-basis: 25%;
        }
      }
    }
    button.elc-add-to-bag-button {
      font-size: 15px;
      line-height: normal;
    }
    .elc-product-cta-wrapper {
      .elc-product-notify-me-button {
        font-size: 14px;
        height: 48px;
        line-height: normal;
      }
    }
  }
}
